import { getAuthBaseUrl } from '~/helpers/api/getBaseUrl'
import { defineAuthFetch, getFetchWithDefaults } from '~/helpers/fetch'

export function useProvideAuthFetch() {
  const runtimeConfig = useRuntimeConfig()
  const cookieRequestHeaders = useRequestHeaders(['cookie'])

  const authFetch = defineAuthFetch(
    getFetchWithDefaults(
      {
        baseURL: getAuthBaseUrl(runtimeConfig),
        credentials: 'include',
      },
      cookieRequestHeaders,
    ),
  )

  return {
    authFetch,
  }
}
